import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const sobremesas = () => {
  return (
    <Layout>
      <Seo title="Menu - Sobremesas" defer={false} />
      <div className="w-full mx-auto lg:mt-10 flex flex-col">
        <div className="flex flex-col w-full h-20 bg-gray-200 justify-center items-center text-md lg:h-32 lg:text-xl lg:font-medium">
          <h1>Ementa</h1>
          <span className="text-xs font-extralight">
            Coma bem, viva melhor!
          </span>
        </div>
        <div className="w-full h-20 bg-sobremesas-image bg-no-repeat bg-center bg-cover lg:h-48"></div>
        <div className=" w-4/5 mx-auto mb-2 mt-5">
          <section className="grid grid-cols-1 gap-12 text-center lg:gap-14 lg:grid-cols-4 mb-10 ">
            <div>
              <div className="w-full h-full  ">
                <img
                  className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                  src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,w_700/v1639664740/sobremesas/1_rk6Ev2tvfELcZ-eNYiYb-P37y-CUn8a_t1xwic.jpg"
                  alt=""
                />
              </div>
              <span className=" text-sm md:text-base lg:text-lg">
                Pudim Molotof
              </span>
            </div>
            <div>
              <div className="w-full h-full  ">
                <img
                  className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                  src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,w_700/v1639664741/sobremesas/1aIaC-gH9-5sOk26Aeb4xuolk2Oh54b68_b3ilwr.jpg"
                  alt=""
                />
              </div>
              <span className=" text-sm md:text-base lg:text-lg">
                Bolo de Chocolate Ferrero
              </span>
            </div>
            <div>
              <div className="w-full h-full  ">
                <img
                  className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                  src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,w_700/v1639664740/sobremesas/1_wtA1KQvC_mksyQhI_qHS3iMNyL7xqRS_vh7yq8.jpg"
                  alt=""
                />
              </div>
              <span className=" text-sm md:text-base lg:text-lg">
                Pudim Tradicional
              </span>
            </div>
            <div>
              <div className="w-full h-full  ">
                <img
                  className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                  src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,w_700/v1639664740/sobremesas/1a2FgufqmjDwc05wnez5kiDu5L8_GtfdH_qcyzqd.jpg"
                  alt=""
                />
              </div>
              <span className=" text-sm md:text-base lg:text-lg">
                Bolo de Noz
              </span>
            </div>
            <div>
              <div className="w-full h-full  ">
                <img
                  className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                  src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,w_700/v1639664740/sobremesas/1arhB8rPtF8XFdpIPO_gZJ_Qq370sK99M_i81r2l.jpg"
                  alt=""
                />
              </div>
              <span className=" text-sm md:text-base lg:text-lg">
                Mousse de Oreo
              </span>
            </div>
            <div>
              <div className="w-full h-full  ">
                <img
                  className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                  src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,w_700/v1639664740/sobremesas/1ahd309ZSMcJ6lW700mDErtlU6IkMoPOW_sh7c8h.jpg"
                  alt=""
                />
              </div>
              <span className=" text-sm md:text-base lg:text-lg">
                Mousse de Chocolate
              </span>
            </div>
            <div>
              <div className="w-full h-full  ">
                <img
                  className=" h-60 w-full md:h-80 object-cover opacity-95 transform hover:opacity-100 hover:scale-110 transition-all ease-in-out shadow-xl"
                  src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,w_700/v1639664742/sobremesas/1_stB3yH4MHQ-XQpLPMi_jZgfgBxJoMdd_a8dlhs.jpg"
                  alt=""
                />
              </div>
              <span className=" text-sm md:text-base lg:text-lg">
                Leite Creme à Monte Alegre
              </span>
            </div>
          </section>
        </div>
        <Link to="/menu" className="mx-auto mb-5 mt-5">
          <button
            type="button"
            className=" text-xs font-medium shadow-md h-8 px-2  text-white transition-colors duration-150 bg-yellow-500 rounded-sm focus:shadow-outline hover:bg-yellow-600 md:px-5 md:h-8"
          >
            Ir para o menu
          </button>
        </Link>
      </div>
    </Layout>
  )
}

export default sobremesas
